import { cabinetApi as api } from "../api";
export const addTagTypes = ["api"] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      coreAcceptTermsOfServiceCreate: build.mutation<
        CoreAcceptTermsOfServiceCreateApiResponse,
        CoreAcceptTermsOfServiceCreateApiArg
      >({
        query: () => ({
          url: `/api/v1_15/core/accept-terms-of-service/`,
          method: "POST",
        }),
        invalidatesTags: ["api"],
      }),
      coreAuthChangeEmailCreate: build.mutation<
        CoreAuthChangeEmailCreateApiResponse,
        CoreAuthChangeEmailCreateApiArg
      >({
        query: () => ({
          url: `/api/v1_15/core/auth/change-email/`,
          method: "POST",
        }),
        invalidatesTags: ["api"],
      }),
      coreAuthIdentityRetrieve: build.query<
        CoreAuthIdentityRetrieveApiResponse,
        CoreAuthIdentityRetrieveApiArg
      >({
        query: () => ({ url: `/api/v1_15/core/auth/identity/` }),
        providesTags: ["api"],
      }),
      coreAuthRegisterPartialUpdate: build.mutation<
        CoreAuthRegisterPartialUpdateApiResponse,
        CoreAuthRegisterPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/core/auth/register/`,
          method: "PATCH",
          body: queryArg,
        }),
        invalidatesTags: ["api"],
      }),
      coreAuthUserRetrieve: build.query<
        CoreAuthUserRetrieveApiResponse,
        CoreAuthUserRetrieveApiArg
      >({
        query: () => ({ url: `/api/v1_15/core/auth/user/` }),
        providesTags: ["api"],
      }),
      coreChangeSubscriptionCreate: build.mutation<
        CoreChangeSubscriptionCreateApiResponse,
        CoreChangeSubscriptionCreateApiArg
      >({
        query: () => ({
          url: `/api/v1_15/core/change_subscription/`,
          method: "POST",
        }),
        invalidatesTags: ["api"],
      }),
      coreCountriesList: build.query<
        CoreCountriesListApiResponse,
        CoreCountriesListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/core/countries/`,
          params: { limit: queryArg.limit, offset: queryArg.offset },
        }),
        providesTags: ["api"],
      }),
      coreCountriesRetrieve: build.query<
        CoreCountriesRetrieveApiResponse,
        CoreCountriesRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/core/countries/${queryArg}/`,
        }),
        providesTags: ["api"],
      }),
      coreCustomerPortalCreate: build.mutation<
        CoreCustomerPortalCreateApiResponse,
        CoreCustomerPortalCreateApiArg
      >({
        query: () => ({
          url: `/api/v1_15/core/customer_portal/`,
          method: "POST",
        }),
        invalidatesTags: ["api"],
      }),
      coreDmConversationsList: build.query<
        CoreDmConversationsListApiResponse,
        CoreDmConversationsListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/core/dm_conversations/`,
          params: { limit: queryArg.limit, offset: queryArg.offset },
        }),
        providesTags: ["api"],
      }),
      coreDmConversationsCreate: build.mutation<
        CoreDmConversationsCreateApiResponse,
        CoreDmConversationsCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/core/dm_conversations/`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["api"],
      }),
      coreDmConversationsCountUnreadRetrieve: build.query<
        CoreDmConversationsCountUnreadRetrieveApiResponse,
        CoreDmConversationsCountUnreadRetrieveApiArg
      >({
        query: () => ({
          url: `/api/v1_15/core/dm_conversations/count_unread/`,
        }),
        providesTags: ["api"],
      }),
      coreDmConversationsUpdateListCreate: build.mutation<
        CoreDmConversationsUpdateListCreateApiResponse,
        CoreDmConversationsUpdateListCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/core/dm_conversations/update_list/`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["api"],
      }),
      coreDmMessagesList: build.query<
        CoreDmMessagesListApiResponse,
        CoreDmMessagesListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/core/dm_messages/`,
          params: { limit: queryArg.limit, offset: queryArg.offset },
        }),
        providesTags: ["api"],
      }),
      coreDmMessagesCreate: build.mutation<
        CoreDmMessagesCreateApiResponse,
        CoreDmMessagesCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/core/dm_messages/`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["api"],
      }),
      coreEmailPrefsCreate: build.mutation<
        CoreEmailPrefsCreateApiResponse,
        CoreEmailPrefsCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/core/email_prefs/`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["api"],
      }),
      coreEmailPrefsUpdate: build.mutation<
        CoreEmailPrefsUpdateApiResponse,
        CoreEmailPrefsUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/core/email_prefs/${queryArg.id}/`,
          method: "PUT",
          body: queryArg.emailPreference,
        }),
        invalidatesTags: ["api"],
      }),
      coreEmailPrefsPartialUpdate: build.mutation<
        CoreEmailPrefsPartialUpdateApiResponse,
        CoreEmailPrefsPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/core/email_prefs/${queryArg.id}/`,
          method: "PATCH",
          body: queryArg.patchedEmailPreference,
        }),
        invalidatesTags: ["api"],
      }),
      coreEmailPrefsMyPrefsRetrieve: build.query<
        CoreEmailPrefsMyPrefsRetrieveApiResponse,
        CoreEmailPrefsMyPrefsRetrieveApiArg
      >({
        query: () => ({ url: `/api/v1_15/core/email_prefs/my_prefs/` }),
        providesTags: ["api"],
      }),
      coreHealthCheckRetrieve: build.query<
        CoreHealthCheckRetrieveApiResponse,
        CoreHealthCheckRetrieveApiArg
      >({
        query: () => ({ url: `/api/v1_15/core/health-check/` }),
        providesTags: ["api"],
      }),
      coreIndustriesList: build.query<
        CoreIndustriesListApiResponse,
        CoreIndustriesListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/core/industries/`,
          params: { limit: queryArg.limit, offset: queryArg.offset },
        }),
        providesTags: ["api"],
      }),
      coreIndustriesRetrieve: build.query<
        CoreIndustriesRetrieveApiResponse,
        CoreIndustriesRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/core/industries/${queryArg}/`,
        }),
        providesTags: ["api"],
      }),
      coreMySubscriptionDetailsRetrieve: build.query<
        CoreMySubscriptionDetailsRetrieveApiResponse,
        CoreMySubscriptionDetailsRetrieveApiArg
      >({
        query: () => ({ url: `/api/v1_15/core/my_subscription_details/` }),
        providesTags: ["api"],
      }),
      coreOrgList: build.query<CoreOrgListApiResponse, CoreOrgListApiArg>({
        query: (queryArg) => ({
          url: `/api/v1_15/core/org/`,
          params: { limit: queryArg.limit, offset: queryArg.offset },
        }),
        providesTags: ["api"],
      }),
      coreOrgRetrieve: build.query<
        CoreOrgRetrieveApiResponse,
        CoreOrgRetrieveApiArg
      >({
        query: (queryArg) => ({ url: `/api/v1_15/core/org/${queryArg}/` }),
        providesTags: ["api"],
      }),
      coreOrgUpdate: build.mutation<
        CoreOrgUpdateApiResponse,
        CoreOrgUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/core/org/${queryArg.id}/`,
          method: "PUT",
          body: queryArg.organization,
        }),
        invalidatesTags: ["api"],
      }),
      coreOrgPartialUpdate: build.mutation<
        CoreOrgPartialUpdateApiResponse,
        CoreOrgPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/core/org/${queryArg.id}/`,
          method: "PATCH",
          body: queryArg.patchedOrganization,
        }),
        invalidatesTags: ["api"],
      }),
      coreOrgPublicOrgRetrieve: build.query<
        CoreOrgPublicOrgRetrieveApiResponse,
        CoreOrgPublicOrgRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/core/org/${queryArg}/public-org/`,
        }),
        providesTags: ["api"],
      }),
      coreOrgProcessLogoCreate: build.mutation<
        CoreOrgProcessLogoCreateApiResponse,
        CoreOrgProcessLogoCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/core/org/process-logo/`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["api"],
      }),
      coreOrgPublicOrgByDomainRetrieve: build.query<
        CoreOrgPublicOrgByDomainRetrieveApiResponse,
        CoreOrgPublicOrgByDomainRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/core/org/public-org-by-domain/${queryArg}/`,
        }),
        providesTags: ["api"],
      }),
      coreOrgPublicOrgByUserRetrieve: build.query<
        CoreOrgPublicOrgByUserRetrieveApiResponse,
        CoreOrgPublicOrgByUserRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/core/org/public-org-by-user/${queryArg}/`,
        }),
        providesTags: ["api"],
      }),
      coreOrgLicensesUpdate: build.mutation<
        CoreOrgLicensesUpdateApiResponse,
        CoreOrgLicensesUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/core/org_licenses/${queryArg.id}/`,
          method: "PUT",
          body: queryArg.organizationLicense,
        }),
        invalidatesTags: ["api"],
      }),
      coreOrgLicensesPartialUpdate: build.mutation<
        CoreOrgLicensesPartialUpdateApiResponse,
        CoreOrgLicensesPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/core/org_licenses/${queryArg.id}/`,
          method: "PATCH",
          body: queryArg.patchedOrganizationLicense,
        }),
        invalidatesTags: ["api"],
      }),
      coreOrgLicensesListByOrgRetrieve: build.query<
        CoreOrgLicensesListByOrgRetrieveApiResponse,
        CoreOrgLicensesListByOrgRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/core/org_licenses/${queryArg}/list-by-org/`,
        }),
        providesTags: ["api"],
      }),
      coreOrgLicensesResendInviteRetrieve: build.query<
        CoreOrgLicensesResendInviteRetrieveApiResponse,
        CoreOrgLicensesResendInviteRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/core/org_licenses/${queryArg}/resend-invite/`,
        }),
        providesTags: ["api"],
      }),
      coreOrgLicensesBulkCreateCreate: build.mutation<
        CoreOrgLicensesBulkCreateCreateApiResponse,
        CoreOrgLicensesBulkCreateCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/core/org_licenses/bulk-create/`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["api"],
      }),
      coreOrgLicensesBulkDeleteDestroy: build.mutation<
        CoreOrgLicensesBulkDeleteDestroyApiResponse,
        CoreOrgLicensesBulkDeleteDestroyApiArg
      >({
        query: () => ({
          url: `/api/v1_15/core/org_licenses/bulk-delete/`,
          method: "DELETE",
        }),
        invalidatesTags: ["api"],
      }),
      coreOrgLicensesBulkUpdatePartialUpdate: build.mutation<
        CoreOrgLicensesBulkUpdatePartialUpdateApiResponse,
        CoreOrgLicensesBulkUpdatePartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/core/org_licenses/bulk-update/`,
          method: "PATCH",
          body: queryArg,
        }),
        invalidatesTags: ["api"],
      }),
      coreOrgUserAutocompleteList: build.query<
        CoreOrgUserAutocompleteListApiResponse,
        CoreOrgUserAutocompleteListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/core/org_user_autocomplete/`,
          params: { limit: queryArg.limit, offset: queryArg.offset },
        }),
        providesTags: ["api"],
      }),
      corePaymentSessionRetrieve: build.query<
        CorePaymentSessionRetrieveApiResponse,
        CorePaymentSessionRetrieveApiArg
      >({
        query: () => ({ url: `/api/v1_15/core/payment_session/` }),
        providesTags: ["api"],
      }),
      coreProfilesList: build.query<
        CoreProfilesListApiResponse,
        CoreProfilesListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/core/profiles/`,
          params: { limit: queryArg.limit, offset: queryArg.offset },
        }),
        providesTags: ["api"],
      }),
      coreProfilesCreate: build.mutation<
        CoreProfilesCreateApiResponse,
        CoreProfilesCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/core/profiles/`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["api"],
      }),
      coreProfilesRetrieve: build.query<
        CoreProfilesRetrieveApiResponse,
        CoreProfilesRetrieveApiArg
      >({
        query: (queryArg) => ({ url: `/api/v1_15/core/profiles/${queryArg}/` }),
        providesTags: ["api"],
      }),
      coreProfilesUpdate: build.mutation<
        CoreProfilesUpdateApiResponse,
        CoreProfilesUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/core/profiles/${queryArg.id}/`,
          method: "PUT",
          body: queryArg.publicUserWithProfile,
        }),
        invalidatesTags: ["api"],
      }),
      coreProfilesPartialUpdate: build.mutation<
        CoreProfilesPartialUpdateApiResponse,
        CoreProfilesPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/core/profiles/${queryArg.id}/`,
          method: "PATCH",
          body: queryArg.patchedPublicUserWithProfile,
        }),
        invalidatesTags: ["api"],
      }),
      coreProfilesDestroy: build.mutation<
        CoreProfilesDestroyApiResponse,
        CoreProfilesDestroyApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/core/profiles/${queryArg}/`,
          method: "DELETE",
        }),
        invalidatesTags: ["api"],
      }),
      coreProfilesSearchRetrieve: build.query<
        CoreProfilesSearchRetrieveApiResponse,
        CoreProfilesSearchRetrieveApiArg
      >({
        query: () => ({ url: `/api/v1_15/core/profiles/search/` }),
        providesTags: ["api"],
      }),
      coreProfilesUploadImgUrlRetrieve: build.query<
        CoreProfilesUploadImgUrlRetrieveApiResponse,
        CoreProfilesUploadImgUrlRetrieveApiArg
      >({
        query: () => ({ url: `/api/v1_15/core/profiles/upload_img_url/` }),
        providesTags: ["api"],
      }),
      coreReferralList: build.query<
        CoreReferralListApiResponse,
        CoreReferralListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/core/referral/`,
          params: { limit: queryArg.limit, offset: queryArg.offset },
        }),
        providesTags: ["api"],
      }),
      coreReferralCreate: build.mutation<
        CoreReferralCreateApiResponse,
        CoreReferralCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/core/referral/`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["api"],
      }),
      coreReferralRetrieve: build.query<
        CoreReferralRetrieveApiResponse,
        CoreReferralRetrieveApiArg
      >({
        query: (queryArg) => ({ url: `/api/v1_15/core/referral/${queryArg}/` }),
        providesTags: ["api"],
      }),
      coreReferralUpdate: build.mutation<
        CoreReferralUpdateApiResponse,
        CoreReferralUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/core/referral/${queryArg.id}/`,
          method: "PUT",
          body: queryArg.referral,
        }),
        invalidatesTags: ["api"],
      }),
      coreReferralPartialUpdate: build.mutation<
        CoreReferralPartialUpdateApiResponse,
        CoreReferralPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/core/referral/${queryArg.id}/`,
          method: "PATCH",
          body: queryArg.patchedReferral,
        }),
        invalidatesTags: ["api"],
      }),
      coreReferralDestroy: build.mutation<
        CoreReferralDestroyApiResponse,
        CoreReferralDestroyApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/core/referral/${queryArg}/`,
          method: "DELETE",
        }),
        invalidatesTags: ["api"],
      }),
      coreReferralReferMultipleCreate: build.mutation<
        CoreReferralReferMultipleCreateApiResponse,
        CoreReferralReferMultipleCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/core/referral/refer_multiple/`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["api"],
      }),
      coreReferralTrackReferralSignupCreate: build.mutation<
        CoreReferralTrackReferralSignupCreateApiResponse,
        CoreReferralTrackReferralSignupCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/core/referral/track-referral-signup/`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["api"],
      }),
      coreSubscriptionDetailsRetrieve: build.query<
        CoreSubscriptionDetailsRetrieveApiResponse,
        CoreSubscriptionDetailsRetrieveApiArg
      >({
        query: () => ({ url: `/api/v1_15/core/subscription_details/` }),
        providesTags: ["api"],
      }),
      coreTermsOfServiceList: build.query<
        CoreTermsOfServiceListApiResponse,
        CoreTermsOfServiceListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/core/terms-of-service/`,
          params: { limit: queryArg.limit, offset: queryArg.offset },
        }),
        providesTags: ["api"],
      }),
      coreTermsOfServiceRetrieve: build.query<
        CoreTermsOfServiceRetrieveApiResponse,
        CoreTermsOfServiceRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/core/terms-of-service/${queryArg}/`,
        }),
        providesTags: ["api"],
      }),
      coreTestApiVerRetrieve: build.query<
        CoreTestApiVerRetrieveApiResponse,
        CoreTestApiVerRetrieveApiArg
      >({
        query: () => ({ url: `/api/v1_15/core/test_api_ver/` }),
        providesTags: ["api"],
      }),
      coreTestNotificationRetrieve: build.query<
        CoreTestNotificationRetrieveApiResponse,
        CoreTestNotificationRetrieveApiArg
      >({
        query: () => ({ url: `/api/v1_15/core/test_notification/` }),
        providesTags: ["api"],
      }),
      coreTestRequestRetrieve: build.query<
        CoreTestRequestRetrieveApiResponse,
        CoreTestRequestRetrieveApiArg
      >({
        query: () => ({ url: `/api/v1_15/core/test_request` }),
        providesTags: ["api"],
      }),
      coreTestRequestCreate: build.mutation<
        CoreTestRequestCreateApiResponse,
        CoreTestRequestCreateApiArg
      >({
        query: () => ({ url: `/api/v1_15/core/test_request`, method: "POST" }),
        invalidatesTags: ["api"],
      }),
      coreTestRequestUpdate: build.mutation<
        CoreTestRequestUpdateApiResponse,
        CoreTestRequestUpdateApiArg
      >({
        query: () => ({ url: `/api/v1_15/core/test_request`, method: "PUT" }),
        invalidatesTags: ["api"],
      }),
      coreTestRequestPartialUpdate: build.mutation<
        CoreTestRequestPartialUpdateApiResponse,
        CoreTestRequestPartialUpdateApiArg
      >({
        query: () => ({ url: `/api/v1_15/core/test_request`, method: "PATCH" }),
        invalidatesTags: ["api"],
      }),
      coreTestRequestDestroy: build.mutation<
        CoreTestRequestDestroyApiResponse,
        CoreTestRequestDestroyApiArg
      >({
        query: () => ({
          url: `/api/v1_15/core/test_request`,
          method: "DELETE",
        }),
        invalidatesTags: ["api"],
      }),
      coreUserAutocompleteList: build.query<
        CoreUserAutocompleteListApiResponse,
        CoreUserAutocompleteListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/core/user_autocomplete/`,
          params: { limit: queryArg.limit, offset: queryArg.offset },
        }),
        providesTags: ["api"],
      }),
      coreUserPrefsList: build.query<
        CoreUserPrefsListApiResponse,
        CoreUserPrefsListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/core/user_prefs/`,
          params: { limit: queryArg.limit, offset: queryArg.offset },
        }),
        providesTags: ["api"],
      }),
      coreUserPrefsCreate: build.mutation<
        CoreUserPrefsCreateApiResponse,
        CoreUserPrefsCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/core/user_prefs/`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["api"],
      }),
      coreUserPrefsRetrieve: build.query<
        CoreUserPrefsRetrieveApiResponse,
        CoreUserPrefsRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/core/user_prefs/${queryArg}/`,
        }),
        providesTags: ["api"],
      }),
      coreUserPrefsUpdate: build.mutation<
        CoreUserPrefsUpdateApiResponse,
        CoreUserPrefsUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/core/user_prefs/${queryArg.id}/`,
          method: "PUT",
          body: queryArg.userPreferences,
        }),
        invalidatesTags: ["api"],
      }),
      coreUserPrefsPartialUpdate: build.mutation<
        CoreUserPrefsPartialUpdateApiResponse,
        CoreUserPrefsPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/core/user_prefs/${queryArg.id}/`,
          method: "PATCH",
          body: queryArg.patchedUserPreferences,
        }),
        invalidatesTags: ["api"],
      }),
      coreUserPrefsDestroy: build.mutation<
        CoreUserPrefsDestroyApiResponse,
        CoreUserPrefsDestroyApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1_15/core/user_prefs/${queryArg}/`,
          method: "DELETE",
        }),
        invalidatesTags: ["api"],
      }),
      coreUserPrefsMyPrefsRetrieve: build.query<
        CoreUserPrefsMyPrefsRetrieveApiResponse,
        CoreUserPrefsMyPrefsRetrieveApiArg
      >({
        query: () => ({ url: `/api/v1_15/core/user_prefs/my_prefs/` }),
        providesTags: ["api"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as coreGenerated };
export type CoreAcceptTermsOfServiceCreateApiResponse = unknown;
export type CoreAcceptTermsOfServiceCreateApiArg = void;
export type CoreAuthChangeEmailCreateApiResponse = unknown;
export type CoreAuthChangeEmailCreateApiArg = void;
export type CoreAuthIdentityRetrieveApiResponse = unknown;
export type CoreAuthIdentityRetrieveApiArg = void;
export type CoreAuthRegisterPartialUpdateApiResponse =
  /** status 200  */ PrivateUserWithProfile;
export type CoreAuthRegisterPartialUpdateApiArg = PatchedPrivateUserWithProfile;
export type CoreAuthUserRetrieveApiResponse =
  /** status 200  */ PrivateUserWithProfile;
export type CoreAuthUserRetrieveApiArg = void;
export type CoreChangeSubscriptionCreateApiResponse = unknown;
export type CoreChangeSubscriptionCreateApiArg = void;
export type CoreCountriesListApiResponse =
  /** status 200  */ PaginatedCountryList;
export type CoreCountriesListApiArg = {
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type CoreCountriesRetrieveApiResponse = /** status 200  */ Country;
export type CoreCountriesRetrieveApiArg =
  /** A unique integer value identifying this Country. */ number;
export type CoreCustomerPortalCreateApiResponse = unknown;
export type CoreCustomerPortalCreateApiArg = void;
export type CoreDmConversationsListApiResponse =
  /** status 200  */ PaginatedDirectMessageConversationList;
export type CoreDmConversationsListApiArg = {
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type CoreDmConversationsCreateApiResponse =
  /** status 201  */ DirectMessageConversation;
export type CoreDmConversationsCreateApiArg = DirectMessageConversation;
export type CoreDmConversationsCountUnreadRetrieveApiResponse =
  /** status 200  */ DirectMessageConversation;
export type CoreDmConversationsCountUnreadRetrieveApiArg = void;
export type CoreDmConversationsUpdateListCreateApiResponse =
  /** status 200  */ DirectMessageConversation;
export type CoreDmConversationsUpdateListCreateApiArg =
  DirectMessageConversation;
export type CoreDmMessagesListApiResponse =
  /** status 200  */ PaginatedDirectMessageList;
export type CoreDmMessagesListApiArg = {
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type CoreDmMessagesCreateApiResponse = /** status 201  */ DirectMessage;
export type CoreDmMessagesCreateApiArg = DirectMessage;
export type CoreEmailPrefsCreateApiResponse =
  /** status 201  */ EmailPreference;
export type CoreEmailPrefsCreateApiArg = EmailPreference;
export type CoreEmailPrefsUpdateApiResponse =
  /** status 200  */ EmailPreference;
export type CoreEmailPrefsUpdateApiArg = {
  /** A unique integer value identifying this email preference. */
  id: number;
  emailPreference: EmailPreference;
};
export type CoreEmailPrefsPartialUpdateApiResponse =
  /** status 200  */ EmailPreference;
export type CoreEmailPrefsPartialUpdateApiArg = {
  /** A unique integer value identifying this email preference. */
  id: number;
  patchedEmailPreference: PatchedEmailPreference;
};
export type CoreEmailPrefsMyPrefsRetrieveApiResponse =
  /** status 200  */ EmailPreference;
export type CoreEmailPrefsMyPrefsRetrieveApiArg = void;
export type CoreHealthCheckRetrieveApiResponse = unknown;
export type CoreHealthCheckRetrieveApiArg = void;
export type CoreIndustriesListApiResponse =
  /** status 200  */ PaginatedIndustryList;
export type CoreIndustriesListApiArg = {
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type CoreIndustriesRetrieveApiResponse = /** status 200  */ Industry;
export type CoreIndustriesRetrieveApiArg =
  /** A unique integer value identifying this Industry. */ number;
export type CoreMySubscriptionDetailsRetrieveApiResponse = unknown;
export type CoreMySubscriptionDetailsRetrieveApiArg = void;
export type CoreOrgListApiResponse =
  /** status 200  */ PaginatedOrganizationList;
export type CoreOrgListApiArg = {
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type CoreOrgRetrieveApiResponse = /** status 200  */ Organization;
export type CoreOrgRetrieveApiArg = string;
export type CoreOrgUpdateApiResponse = /** status 200  */ Organization;
export type CoreOrgUpdateApiArg = {
  id: string;
  organization: Organization;
};
export type CoreOrgPartialUpdateApiResponse = /** status 200  */ Organization;
export type CoreOrgPartialUpdateApiArg = {
  id: string;
  patchedOrganization: PatchedOrganization;
};
export type CoreOrgPublicOrgRetrieveApiResponse =
  /** status 200  */ PublicOrganization;
export type CoreOrgPublicOrgRetrieveApiArg = string;
export type CoreOrgProcessLogoCreateApiResponse =
  /** status 200  */ Organization;
export type CoreOrgProcessLogoCreateApiArg = Organization;
export type CoreOrgPublicOrgByDomainRetrieveApiResponse =
  /** status 200  */ PublicOrganization;
export type CoreOrgPublicOrgByDomainRetrieveApiArg = string;
export type CoreOrgPublicOrgByUserRetrieveApiResponse =
  /** status 200  */ PublicOrganization;
export type CoreOrgPublicOrgByUserRetrieveApiArg = string;
export type CoreOrgLicensesUpdateApiResponse =
  /** status 200  */ OrganizationLicense;
export type CoreOrgLicensesUpdateApiArg = {
  /** A unique integer value identifying this organization license. */
  id: number;
  organizationLicense: OrganizationLicense;
};
export type CoreOrgLicensesPartialUpdateApiResponse =
  /** status 200  */ OrganizationLicense;
export type CoreOrgLicensesPartialUpdateApiArg = {
  /** A unique integer value identifying this organization license. */
  id: number;
  patchedOrganizationLicense: PatchedOrganizationLicense;
};
export type CoreOrgLicensesListByOrgRetrieveApiResponse =
  /** status 200  */ OrganizationLicense;
export type CoreOrgLicensesListByOrgRetrieveApiArg =
  /** A unique integer value identifying this organization license. */ number;
export type CoreOrgLicensesResendInviteRetrieveApiResponse =
  /** status 200  */ OrganizationLicense;
export type CoreOrgLicensesResendInviteRetrieveApiArg =
  /** A unique integer value identifying this organization license. */ number;
export type CoreOrgLicensesBulkCreateCreateApiResponse =
  /** status 200  */ OrganizationLicense;
export type CoreOrgLicensesBulkCreateCreateApiArg = OrganizationLicense;
export type CoreOrgLicensesBulkDeleteDestroyApiResponse = unknown;
export type CoreOrgLicensesBulkDeleteDestroyApiArg = void;
export type CoreOrgLicensesBulkUpdatePartialUpdateApiResponse =
  /** status 200  */ OrganizationLicense;
export type CoreOrgLicensesBulkUpdatePartialUpdateApiArg =
  PatchedOrganizationLicense;
export type CoreOrgUserAutocompleteListApiResponse =
  /** status 200  */ PaginatedOrganizationUserAutocompleteOptionList;
export type CoreOrgUserAutocompleteListApiArg = {
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type CorePaymentSessionRetrieveApiResponse = unknown;
export type CorePaymentSessionRetrieveApiArg = void;
export type CoreProfilesListApiResponse =
  /** status 200  */ PaginatedPublicUserWithProfileList;
export type CoreProfilesListApiArg = {
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type CoreProfilesCreateApiResponse =
  /** status 201  */ PublicUserWithProfile;
export type CoreProfilesCreateApiArg = PublicUserWithProfile;
export type CoreProfilesRetrieveApiResponse =
  /** status 200  */ PublicUserWithProfile;
export type CoreProfilesRetrieveApiArg =
  /** A unique integer value identifying this user. */ number;
export type CoreProfilesUpdateApiResponse =
  /** status 200  */ PublicUserWithProfile;
export type CoreProfilesUpdateApiArg = {
  /** A unique integer value identifying this user. */
  id: number;
  publicUserWithProfile: PublicUserWithProfile;
};
export type CoreProfilesPartialUpdateApiResponse =
  /** status 200  */ PublicUserWithProfile;
export type CoreProfilesPartialUpdateApiArg = {
  /** A unique integer value identifying this user. */
  id: number;
  patchedPublicUserWithProfile: PatchedPublicUserWithProfile;
};
export type CoreProfilesDestroyApiResponse = unknown;
export type CoreProfilesDestroyApiArg =
  /** A unique integer value identifying this user. */ number;
export type CoreProfilesSearchRetrieveApiResponse =
  /** status 200  */ PublicUserWithProfile;
export type CoreProfilesSearchRetrieveApiArg = void;
export type CoreProfilesUploadImgUrlRetrieveApiResponse =
  /** status 200  */ PublicUserWithProfile;
export type CoreProfilesUploadImgUrlRetrieveApiArg = void;
export type CoreReferralListApiResponse =
  /** status 200  */ PaginatedReferralList;
export type CoreReferralListApiArg = {
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type CoreReferralCreateApiResponse = /** status 201  */ Referral;
export type CoreReferralCreateApiArg = Referral;
export type CoreReferralRetrieveApiResponse = /** status 200  */ Referral;
export type CoreReferralRetrieveApiArg =
  /** A unique integer value identifying this referral. */ number;
export type CoreReferralUpdateApiResponse = /** status 200  */ Referral;
export type CoreReferralUpdateApiArg = {
  /** A unique integer value identifying this referral. */
  id: number;
  referral: Referral;
};
export type CoreReferralPartialUpdateApiResponse = /** status 200  */ Referral;
export type CoreReferralPartialUpdateApiArg = {
  /** A unique integer value identifying this referral. */
  id: number;
  patchedReferral: PatchedReferral;
};
export type CoreReferralDestroyApiResponse = unknown;
export type CoreReferralDestroyApiArg =
  /** A unique integer value identifying this referral. */ number;
export type CoreReferralReferMultipleCreateApiResponse =
  /** status 200  */ Referral;
export type CoreReferralReferMultipleCreateApiArg = Referral;
export type CoreReferralTrackReferralSignupCreateApiResponse =
  /** status 200  */ Referral;
export type CoreReferralTrackReferralSignupCreateApiArg = Referral;
export type CoreSubscriptionDetailsRetrieveApiResponse = unknown;
export type CoreSubscriptionDetailsRetrieveApiArg = void;
export type CoreTermsOfServiceListApiResponse =
  /** status 200  */ PaginatedCabinetTermsOfServiceList;
export type CoreTermsOfServiceListApiArg = {
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type CoreTermsOfServiceRetrieveApiResponse =
  /** status 200  */ CabinetTermsOfService;
export type CoreTermsOfServiceRetrieveApiArg =
  /** A unique integer value identifying this cabinet terms of service. */ number;
export type CoreTestApiVerRetrieveApiResponse = unknown;
export type CoreTestApiVerRetrieveApiArg = void;
export type CoreTestNotificationRetrieveApiResponse = unknown;
export type CoreTestNotificationRetrieveApiArg = void;
export type CoreTestRequestRetrieveApiResponse = unknown;
export type CoreTestRequestRetrieveApiArg = void;
export type CoreTestRequestCreateApiResponse = unknown;
export type CoreTestRequestCreateApiArg = void;
export type CoreTestRequestUpdateApiResponse = unknown;
export type CoreTestRequestUpdateApiArg = void;
export type CoreTestRequestPartialUpdateApiResponse = unknown;
export type CoreTestRequestPartialUpdateApiArg = void;
export type CoreTestRequestDestroyApiResponse = unknown;
export type CoreTestRequestDestroyApiArg = void;
export type CoreUserAutocompleteListApiResponse =
  /** status 200  */ PaginatedUserAutocompleteList;
export type CoreUserAutocompleteListApiArg = {
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type CoreUserPrefsListApiResponse =
  /** status 200  */ PaginatedUserPreferencesList;
export type CoreUserPrefsListApiArg = {
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type CoreUserPrefsCreateApiResponse = /** status 201  */ UserPreferences;
export type CoreUserPrefsCreateApiArg = UserPreferences;
export type CoreUserPrefsRetrieveApiResponse =
  /** status 200  */ UserPreferences;
export type CoreUserPrefsRetrieveApiArg =
  /** A unique integer value identifying this user preferences. */ number;
export type CoreUserPrefsUpdateApiResponse = /** status 200  */ UserPreferences;
export type CoreUserPrefsUpdateApiArg = {
  /** A unique integer value identifying this user preferences. */
  id: number;
  userPreferences: UserPreferences;
};
export type CoreUserPrefsPartialUpdateApiResponse =
  /** status 200  */ UserPreferences;
export type CoreUserPrefsPartialUpdateApiArg = {
  /** A unique integer value identifying this user preferences. */
  id: number;
  patchedUserPreferences: PatchedUserPreferences;
};
export type CoreUserPrefsDestroyApiResponse = unknown;
export type CoreUserPrefsDestroyApiArg =
  /** A unique integer value identifying this user preferences. */ number;
export type CoreUserPrefsMyPrefsRetrieveApiResponse =
  /** status 200  */ UserPreferences;
export type CoreUserPrefsMyPrefsRetrieveApiArg = void;
export type Profile = {
  id: number;
  user: number;
  user_leader?: number | null;
  company?: string | null;
  industry?: number | null;
  industry_display: string;
  role?: string | null;
  about_me?: string | null;
  valid_pic_url: string;
  referral_url: string;
  country?: number | null;
  country_code: string;
  location_display: string;
  postal_code?: string | null;
  state?: string | null;
  state_short?: string | null;
  city?: string | null;
  expertise_areas: string;
  moderator: boolean;
  source: string;
  registration_completed: boolean;
  app_onboarding_completed?: boolean;
  /** * `BLOG_POST` - Blog post
   * `GOOGLE_SEARCH` - Google search
   * `COMPANY_USER` - My Company Uses Cabinet
   * `WORD_OF_MOUTH` - Word of mouth
   * `SCHEDULING_LINK` - Scheduling link
   * `EVENT` - Event
   * `CABINET_NEWSLETTER` - Cabinet newsletter
   * `EMAIL_FROM_CABINET` - Email from Cabinet
   * `LINKEDIN_MESSAGE_FROM_CABINET` - LinkedIn message from Cabinet
   * `OTHER` - Other */
  reference_type?:
    | (
        | "BLOG_POST"
        | "GOOGLE_SEARCH"
        | "COMPANY_USER"
        | "WORD_OF_MOUTH"
        | "SCHEDULING_LINK"
        | "EVENT"
        | "CABINET_NEWSLETTER"
        | "EMAIL_FROM_CABINET"
        | "LINKEDIN_MESSAGE_FROM_CABINET"
        | "OTHER"
        | ""
        | null
      )
    | null;
};
export type UserStats = {
  id: number;
  points?: number | null;
  top_contributor?: boolean;
};
export type UserPreferences = {
  id: number;
  user: number;
  profile_searchable?: boolean;
  task_time_track_remind?: boolean;
  due_email?: boolean;
  due_push?: boolean;
  digest_email?: boolean;
  digest_push?: boolean;
  digest_all?: boolean;
};
export type PrivateUserWithProfile = {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  profile: Profile;
  stats: UserStats;
  prefs: UserPreferences;
  oauth_grant_details: string;
  features: string;
  licenses: string;
  active_license: string;
  permissions: string;
  identity_provider: string | null;
  tos_acceptance_required: boolean;
};
export type PatchedPrivateUserWithProfile = {
  id?: number;
  first_name?: string;
  last_name?: string;
  email?: string;
  profile?: Profile;
  stats?: UserStats;
  prefs?: UserPreferences;
  oauth_grant_details?: string;
  features?: string;
  licenses?: string;
  active_license?: string;
  permissions?: string;
  identity_provider?: string | null;
  tos_acceptance_required?: boolean;
};
export type Country = {
  id: number;
  code?: string | null;
  name?: string | null;
};
export type PaginatedCountryList = {
  data: Country[];
  meta: {
    count?: number;
    next?: string | null;
    previous?: string | null;
    data_map?: object;
  };
};
export type Industry = {
  id: number;
  short_name: string;
  display_name: string;
  description: string;
};
export type UserMeta = {
  id: number;
  industry: Industry;
  valid_pic_url: string;
  location_display: string;
  city: string | null;
  state: string | null;
  state_short: string | null;
};
export type UserInfo = {
  id: number;
  first_name?: string;
  last_name?: string;
  profile: UserMeta;
  stats: UserStats;
};
export type DirectMessageConversation = {
  id: number;
  initiator: number;
  initiator_info: UserInfo;
  create_date: string;
  last_message_date: string | null;
  users: number[];
  users_info: UserInfo[];
  first_add?: number | null;
  first_add_info: UserInfo;
  unread_by: number[];
  preview: string;
};
export type PaginatedDirectMessageConversationList = {
  data: DirectMessageConversation[];
  meta: {
    count?: number;
    next?: string | null;
    previous?: string | null;
    data_map?: object;
  };
};
export type DirectMessage = {
  id: number;
  from_user: number;
  create_date: string;
  conversation: number;
  content?: string | null;
};
export type PaginatedDirectMessageList = {
  data: DirectMessage[];
  meta: {
    count?: number;
    next?: string | null;
    previous?: string | null;
    data_map?: object;
  };
};
export type EmailPreference = {
  id: number;
  user: number;
  answers?: boolean;
  answer_upvotes?: boolean;
  answer_replies?: boolean;
  other_replies?: boolean;
  new_messages?: boolean;
};
export type PatchedEmailPreference = {
  id?: number;
  user?: number;
  answers?: boolean;
  answer_upvotes?: boolean;
  answer_replies?: boolean;
  other_replies?: boolean;
  new_messages?: boolean;
};
export type PaginatedIndustryList = {
  data: Industry[];
  meta: {
    count?: number;
    next?: string | null;
    previous?: string | null;
    data_map?: object;
  };
};
export type Organization = {
  id: number;
  name: string;
  logo?: string | null;
  logo_url: string | null;
  remove_background?: boolean;
  /** * `nearest` - nearest
   * `box` - box
   * `bilinear` - bilinear
   * `hamming` - hamming
   * `bicubic` - bicubic
   * `lanczos` - lanczos */
  resample_type?:
    | "nearest"
    | "box"
    | "bilinear"
    | "hamming"
    | "bicubic"
    | "lanczos";
  /** * `Unknown` - Unknown
   * `0` - None
   * `1` - Just Me
   * `2-5` - 2-5
   * `6-10` - 6-10
   * `11-30` - 11-30
   * `31-100` - 31-100
   * `101+` - 101+
   * `1-9` - 1-9
   * `10-19` - 10-19
   * `20-49` - 20-49
   * `50+` - 50+ */
  num_eas?:
    | (
        | "Unknown"
        | "0"
        | "1"
        | "2-5"
        | "6-10"
        | "11-30"
        | "31-100"
        | "101+"
        | "1-9"
        | "10-19"
        | "20-49"
        | "50+"
        | null
      )
    | null;
  is_on_contract?: boolean;
  /** * `14400` - Four Hours
   * `21600` - Six Hours
   * `28800` - Eight Hours */
  max_session_length?: (14400 | 21600 | 28800 | null) | null;
  unpaid_trial_end_date?: string | null;
  active: boolean;
  trialing: boolean;
  trial_end: string | null;
  onboarding_call_scheduled?: boolean;
  idle_timeout?: number | null;
  logout_redirect_uri?: string | null;
};
export type PaginatedOrganizationList = {
  data: Organization[];
  meta: {
    count?: number;
    next?: string | null;
    previous?: string | null;
    data_map?: object;
  };
};
export type PatchedOrganization = {
  id?: number;
  name?: string;
  logo?: string | null;
  logo_url?: string | null;
  remove_background?: boolean;
  /** * `nearest` - nearest
   * `box` - box
   * `bilinear` - bilinear
   * `hamming` - hamming
   * `bicubic` - bicubic
   * `lanczos` - lanczos */
  resample_type?:
    | "nearest"
    | "box"
    | "bilinear"
    | "hamming"
    | "bicubic"
    | "lanczos";
  /** * `Unknown` - Unknown
   * `0` - None
   * `1` - Just Me
   * `2-5` - 2-5
   * `6-10` - 6-10
   * `11-30` - 11-30
   * `31-100` - 31-100
   * `101+` - 101+
   * `1-9` - 1-9
   * `10-19` - 10-19
   * `20-49` - 20-49
   * `50+` - 50+ */
  num_eas?:
    | (
        | "Unknown"
        | "0"
        | "1"
        | "2-5"
        | "6-10"
        | "11-30"
        | "31-100"
        | "101+"
        | "1-9"
        | "10-19"
        | "20-49"
        | "50+"
        | null
      )
    | null;
  is_on_contract?: boolean;
  /** * `14400` - Four Hours
   * `21600` - Six Hours
   * `28800` - Eight Hours */
  max_session_length?: (14400 | 21600 | 28800 | null) | null;
  unpaid_trial_end_date?: string | null;
  active?: boolean;
  trialing?: boolean;
  trial_end?: string | null;
  onboarding_call_scheduled?: boolean;
  idle_timeout?: number | null;
  logout_redirect_uri?: string | null;
};
export type PublicOrganization = {
  id: number;
  name: string | null;
  logo: string | null;
  cognito_provider_name: string | null;
  org_code: string | null;
  onboarding_call_scheduled: boolean;
};
export type OrganizationLicense = {
  id?: number;
  email: string;
  organization: number;
  /** * `ESSENTIALS` - ESSENTIALS
   * `PRO` - PRO
   * `INDIVIDUAL` - INDIVIDUAL
   * `STARTER` - STARTER
   * `GROWTH` - GROWTH
   * `PREMIER` - PREMIER
   * `BASIC` - BASIC */
  tier:
    | "ESSENTIALS"
    | "PRO"
    | "INDIVIDUAL"
    | "STARTER"
    | "GROWTH"
    | "PREMIER"
    | "BASIC";
  license_tier: string;
  name: string;
  user: number | null;
  /** * `assistant` - Assistant
   * `individual` - Individual */
  user_role: "assistant" | "individual";
  assists_leaders: {
    id: number;
    first_name: string;
    last_name: string;
    leader_user: number | null;
  }[];
  onsignup_assistant: number | null;
  onsignup_leaders: number[];
  org_permissions_groups: number[];
  created_by?: number | null;
  is_active: boolean;
};
export type PatchedOrganizationLicense = {
  id?: number;
  email?: string;
  organization?: number;
  /** * `ESSENTIALS` - ESSENTIALS
   * `PRO` - PRO
   * `INDIVIDUAL` - INDIVIDUAL
   * `STARTER` - STARTER
   * `GROWTH` - GROWTH
   * `PREMIER` - PREMIER
   * `BASIC` - BASIC */
  tier?:
    | "ESSENTIALS"
    | "PRO"
    | "INDIVIDUAL"
    | "STARTER"
    | "GROWTH"
    | "PREMIER"
    | "BASIC";
  license_tier?: string;
  name?: string;
  user?: number | null;
  /** * `assistant` - Assistant
   * `individual` - Individual */
  user_role?: "assistant" | "individual";
  assists_leaders?: {
    id: number;
    first_name: string;
    last_name: string;
    leader_user: number | null;
  }[];
  onsignup_assistant?: number | null;
  onsignup_leaders?: number[];
  org_permissions_groups?: number[];
  created_by?: number | null;
  is_active?: boolean;
};
export type OrganizationUserAutocompleteOption = {
  label: number;
  /** Given the *incoming* primitive data, return the value for this field
    that should be validated and transformed to a native value. */
  value: number;
};
export type PaginatedOrganizationUserAutocompleteOptionList = {
  data: OrganizationUserAutocompleteOption[];
  meta: {
    count?: number;
    next?: string | null;
    previous?: string | null;
    data_map?: object;
  };
};
export type PublicUserWithProfile = {
  id: number;
  first_name?: string;
  last_name?: string;
  profile: Profile;
  stats: UserStats;
};
export type PaginatedPublicUserWithProfileList = {
  data: PublicUserWithProfile[];
  meta: {
    count?: number;
    next?: string | null;
    previous?: string | null;
    data_map?: object;
  };
};
export type PatchedPublicUserWithProfile = {
  id?: number;
  first_name?: string;
  last_name?: string;
  profile?: Profile;
  stats?: UserStats;
};
export type Referral = {
  id: number;
  email?: string | null;
  first_name?: string | null;
  code: string | null;
};
export type PaginatedReferralList = {
  data: Referral[];
  meta: {
    count?: number;
    next?: string | null;
    previous?: string | null;
    data_map?: object;
  };
};
export type PatchedReferral = {
  id?: number;
  email?: string | null;
  first_name?: string | null;
  code?: string | null;
};
export type CabinetTermsOfService = {
  version: string;
  tos_content: string;
};
export type PaginatedCabinetTermsOfServiceList = {
  data: CabinetTermsOfService[];
  meta: {
    count?: number;
    next?: string | null;
    previous?: string | null;
    data_map?: object;
  };
};
export type UserAutocompleteProfile = {
  city: string | null;
  state_short: string | null;
};
export type UserAutocomplete = {
  id: number;
  first_name: string;
  last_name: string;
  more_info: UserAutocompleteProfile;
};
export type PaginatedUserAutocompleteList = {
  data: UserAutocomplete[];
  meta: {
    count?: number;
    next?: string | null;
    previous?: string | null;
    data_map?: object;
  };
};
export type PaginatedUserPreferencesList = {
  data: UserPreferences[];
  meta: {
    count?: number;
    next?: string | null;
    previous?: string | null;
    data_map?: object;
  };
};
export type PatchedUserPreferences = {
  id?: number;
  user?: number;
  profile_searchable?: boolean;
  task_time_track_remind?: boolean;
  due_email?: boolean;
  due_push?: boolean;
  digest_email?: boolean;
  digest_push?: boolean;
  digest_all?: boolean;
};
export const {
  useCoreAcceptTermsOfServiceCreateMutation,
  useCoreAuthChangeEmailCreateMutation,
  useCoreAuthIdentityRetrieveQuery,
  useCoreAuthRegisterPartialUpdateMutation,
  useCoreAuthUserRetrieveQuery,
  useCoreChangeSubscriptionCreateMutation,
  useCoreCountriesListQuery,
  useCoreCountriesRetrieveQuery,
  useCoreCustomerPortalCreateMutation,
  useCoreDmConversationsListQuery,
  useCoreDmConversationsCreateMutation,
  useCoreDmConversationsCountUnreadRetrieveQuery,
  useCoreDmConversationsUpdateListCreateMutation,
  useCoreDmMessagesListQuery,
  useCoreDmMessagesCreateMutation,
  useCoreEmailPrefsCreateMutation,
  useCoreEmailPrefsUpdateMutation,
  useCoreEmailPrefsPartialUpdateMutation,
  useCoreEmailPrefsMyPrefsRetrieveQuery,
  useCoreHealthCheckRetrieveQuery,
  useCoreIndustriesListQuery,
  useCoreIndustriesRetrieveQuery,
  useCoreMySubscriptionDetailsRetrieveQuery,
  useCoreOrgListQuery,
  useCoreOrgRetrieveQuery,
  useCoreOrgUpdateMutation,
  useCoreOrgPartialUpdateMutation,
  useCoreOrgPublicOrgRetrieveQuery,
  useCoreOrgProcessLogoCreateMutation,
  useCoreOrgPublicOrgByDomainRetrieveQuery,
  useCoreOrgPublicOrgByUserRetrieveQuery,
  useCoreOrgLicensesUpdateMutation,
  useCoreOrgLicensesPartialUpdateMutation,
  useCoreOrgLicensesListByOrgRetrieveQuery,
  useCoreOrgLicensesResendInviteRetrieveQuery,
  useCoreOrgLicensesBulkCreateCreateMutation,
  useCoreOrgLicensesBulkDeleteDestroyMutation,
  useCoreOrgLicensesBulkUpdatePartialUpdateMutation,
  useCoreOrgUserAutocompleteListQuery,
  useCorePaymentSessionRetrieveQuery,
  useCoreProfilesListQuery,
  useCoreProfilesCreateMutation,
  useCoreProfilesRetrieveQuery,
  useCoreProfilesUpdateMutation,
  useCoreProfilesPartialUpdateMutation,
  useCoreProfilesDestroyMutation,
  useCoreProfilesSearchRetrieveQuery,
  useCoreProfilesUploadImgUrlRetrieveQuery,
  useCoreReferralListQuery,
  useCoreReferralCreateMutation,
  useCoreReferralRetrieveQuery,
  useCoreReferralUpdateMutation,
  useCoreReferralPartialUpdateMutation,
  useCoreReferralDestroyMutation,
  useCoreReferralReferMultipleCreateMutation,
  useCoreReferralTrackReferralSignupCreateMutation,
  useCoreSubscriptionDetailsRetrieveQuery,
  useCoreTermsOfServiceListQuery,
  useCoreTermsOfServiceRetrieveQuery,
  useCoreTestApiVerRetrieveQuery,
  useCoreTestNotificationRetrieveQuery,
  useCoreTestRequestRetrieveQuery,
  useCoreTestRequestCreateMutation,
  useCoreTestRequestUpdateMutation,
  useCoreTestRequestPartialUpdateMutation,
  useCoreTestRequestDestroyMutation,
  useCoreUserAutocompleteListQuery,
  useCoreUserPrefsListQuery,
  useCoreUserPrefsCreateMutation,
  useCoreUserPrefsRetrieveQuery,
  useCoreUserPrefsUpdateMutation,
  useCoreUserPrefsPartialUpdateMutation,
  useCoreUserPrefsDestroyMutation,
  useCoreUserPrefsMyPrefsRetrieveQuery,
} = injectedRtkApi;
